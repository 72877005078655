<!-- _ab: `${config.abTest}-${store.state.abTest.abTestVersionTag}` -->

<template>
  <div
    v-if="showQR && isShow"
    class="operation-bottom-qr-container"
    :class="`${from}-page`"
    :style="{ backgroundImage: `url(${currBg})` }"
  >
    <div @click="handleCloseModal" class="close"></div>

    <p class="title">{{ msg }}</p>
    <div class="main-countdown">
      <span class="txt">仅剩:</span> <span class="num">{{ countdown }}</span>
    </div>
    <p class="qr-text" :class="[isLogin && 'short']">{{ qrText }}</p>

    <div class="qrcode">
      <img :src="qrCodeURL" />
    </div>
  </div>
</template>

<script>
import useQRCode from '@/composables/useQRCode'
import { sendLog } from '@/directives/v-log/log'
import { getCarplayInfo } from '@/service/carplay-info'
import { clearVipQueryPolling, getVipPkgQr, getVipQuery } from '@/service/vip'
import store from '@/store'
import { getRetainModalTag, getUserRoleTag } from '@/utils/historyCache'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { computed, onActivated, onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue'

export default {
  name: 'OperationBottomQr',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
    msg: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    closeCallback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { from, isShow, closeCallback } = toRefs(props)
    const unionid = computed(() => store.state.userInfo.unionid)
    const isLogin = computed(() => !!unionid.value)
    const userType = computed(() => store.state.userInfo.userType)
    const isVip = computed(() => !!store.state.vipInfo.end_time)

    const { getQRCodeURL } = useQRCode()
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const isNewUser = ref(false)
    const showQR = ref(false)
    const currSelected = ref(0)
    const pkg = [
      {
        id: 18,
        value: '2年',
        img: 'https://qncweb.ktvsky.com/20250319/vadd/f163b38bedc61f803a60e6362be02a4c.png',
        // img: 'https://qncweb.ktvsky.com/20241209/vadd/7498211ad77acc41f885f1535c3a8177.png',
      },
      {
        id: 18,
        value: '2年',
        img: 'https://qncweb.ktvsky.com/20250319/vadd/767e3dbc3598630cee25a507238a1826.png',
        // img: 'https://qncweb.ktvsky.com/20241209/vadd/7498211ad77acc41f885f1535c3a8177.png',
      },
    ]
    // 判断使用配置1  还是配置2
    currSelected.value = from.value === 'search' ? 0 : 1
    const currBg = computed(() => pkg[currSelected.value].img)

    const qrText = computed(() => {
      return isLogin.value ? '微信扫码支付' : '微信扫码登录支付'
    })
    const countdown = ref('00:59:59')
    let timer = null

    const startCountdown = () => {
      let totalSeconds = 1 * 3600 - 1 // 1小时59分59秒
      timer = setInterval(() => {
        if (totalSeconds < 0) {
          clearInterval(timer)
          handleCloseModal()
          return
        }
        const hours = Math.floor(totalSeconds / 3600)
        const minutes = Math.floor((totalSeconds % 3600) / 60)
        const seconds = totalSeconds % 60

        countdown.value = `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

        totalSeconds--
      }, 1000)
    }
    const getZdVipQrcode = async () => {
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: pkg[currSelected.value].id,
          fr: from.value === 'search' ? 1903 : 1905,
        })
        const qr = get(data, 'qr', '')
        if (qr) {
          qrCodeURL.value = await getQRCodeURL(qr)
          await getVipQuery(data.order_id)
          store.dispatch('getCarplayInfo')
          clearVipQueryPolling()
        }
      } catch (error) {
        clearVipQueryPolling()
      }
    }

    const getMobileVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(
          `${data.pay_qr}&log=${from.value === 'search' ? 1902 : 1904}&ut=${
            userType.value
          }`
        )
        console.log('getVipQrcode qrCodeData:', qrCodeData.value)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const getVipQrcode = async () => {
      console.log('operation qr mounted')
      // if (!getRetainModalTag()) {
      //   showQR.value = false
      //   return
      // }

      if (isLogin.value) {
        getZdVipQrcode()
        return
      }
      getMobileVipQrcode()
    }

    const handleCloseModal = () => {
      showQR.value = false
      clearVipQueryPolling()
      store.dispatch('getCarplayInfo')

      closeCallback.value && closeCallback.value()
    }

    watch(isVip, (val) => {
      if (val) {
        showQR.value = false
      }
    })

    watch([showQR, isShow], ([showQR, isShow]) => {
      if (showQR && isShow) {
        sendLog({
          event_type: '30000～35000',
          event_name: from.value === 'search' ? 30327 : 30328,
          event_data: {
            str1:
              from.value === 'search' ? '搜索结果页运营位' : '歌手详情页运营位',
            str2: from.value === 'search' ? '搜索结果页' : '歌手详情页',
            str3: '展示',
            str4: 'show',
            str5: isLogin.value ? '已登录' : '未登录',
            str7: isNewUser.value === 2 ? '2' : '1',
          },
        })
      }
    })
    onBeforeUnmount(() => {
      if (timer) {
        clearInterval(timer)
      }
    })
    onActivated(() => {
      console.log('operation qr onActivated', isVip.value)
      isNewUser.value = getUserRoleTag()
      if (!isVip.value) {
        showQR.value = true
        getVipQrcode()
        return
      }
      showQR.value = false
    })

    onMounted(() => {
      console.log('operation qr mounted')
      isNewUser.value = getUserRoleTag()
      if (!isVip.value) {
        showQR.value = true
        getVipQrcode()
        startCountdown()
        return
      }
      showQR.value = false
    })

    return {
      showQR,
      qrText,
      qrCodeURL,
      currBg,
      isLogin,
      countdown,
      handleCloseModal,
    }
  },
}
</script>

<style lang="stylus" scoped>
.operation-bottom-qr
  &-container
    position fixed
    width 873px
    height 231px
    bottom 130px
    z-index 5
    left 50%
    transform translateX(-50%)
    background url('') no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px)
      zoom 0.9
      bottom 125px
    .close
      position absolute
      top 0px
      right 0px
      width 45px
      height 45px
    .title
      position absolute
      width 412px
      top 122px
      right 16px
      left auto
      font-size 32px
      font-weight 700
      line-height 1
      text-align left
      color rgba(46, 123, 9, 1)
      white-space nowrap
    .qr-text
      position absolute
      width 160px
      top 200px
      left 21px
      font-size 20px
      font-weight 500
      color rgba(0, 0, 0, 0.6)
      text-align center
    .short
      right 80px
    .main-countdown
      position absolute
      top 57px
      left 237px
      font-weight 500
      font-size 26px
      color rgba(25, 79, 0, 1)
      white-space nowrap
      .txt 
        font-size 22px
    .qrcode
      position absolute
      top 60px
      left 31px
      width 140px
      height 140px
      display flex
      justify-content center
      align-items center
      background #FFFFFF
      border-radius 10px
      img
        width 132px
        height 132px
        border-radius 5px
.songlist-page
  width 747px!important
  height 319px!important
  right 49px!important
  bottom 120px
  left auto!important
  transform: none
  .title
    width 470px
    top 210px
    background linear-gradient(180deg, rgba(135, 42, 25, 1) 0%, rgba(199, 84, 64, 1) 100%)
    -webkit-background-clip text
    -webkit-text-fill-color transparent
  .qrcode
    top 116px
    left 57px
  .qr-text
    top 258px
    left 47px
  .main-countdown
    top 104px!important
    left auto!important
    right 32px!important
    font-size 22px
    color #fff
    text-align left
    .num
      font-variant-numeric: tabular-nums; /* 现代浏览器支持的数字等宽特性 */
  .close
    right 20px
    top 20px
</style>
